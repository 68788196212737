import React from "react";

import AllIntroduction from "../../components/AllIntroduction/AllIntroduction";

import AllTestimonials from "../../components/AllTestimonials/AllTestimonials";

import SolarCalculator from "../../components/SolarCalculator/SolarCalculator";
import SolarHighlights from "../../components/SolarHighlights/SolarHighlights";
import InternalContactUs from "../../components/InternalContactUs/InternalContactUs";

const SolarCalculatorPage = () => {
  return (
    <div>
      <AllIntroduction />
      <SolarHighlights />
      <SolarCalculator />
      <AllTestimonials />
      <InternalContactUs/>
    </div>
  );
};

export default SolarCalculatorPage;
