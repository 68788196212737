import React from "react";
import { useParams } from "react-router-dom";
import services from "../../JsonFiles/Services.json";
import projects from "../../JsonFiles/Projects.json";
import AllIntroduction from "../../components/AllIntroduction/AllIntroduction";
import AllAboutSection from "../../components/AllAboutSection/AllAboutSection";
import AllProjects from "../../components/AllProjects/AllProjects";
import AllFaqs from "../../components/AllFaqs/AllFaqs";
import SolarQuestions from "../../components/SolarQuestions/SolarQuestions";
import SolarHighlights from "../../components/SolarHighlights/SolarHighlights";

import AllTestimonials from "../../components/AllTestimonials/AllTestimonials";
import InternalContactUs from "../../components/InternalContactUs/InternalContactUs";
const SolarPage = () => {
  const { id } = useParams();
  const data = services.find((page) => page.id === parseInt(id));
  const projectData = projects.find((page) => page.id === parseInt(id));

  if (!data) {
    // Corrected from "date" to "data"
    return <div>Page not found</div>;
  }

  return (
    <div>
      <AllIntroduction title={data.title} description={data.description} image={data.image}/>
      <AllAboutSection  />
      <SolarQuestions />
      <SolarHighlights />
      <AllProjects cards={projects} />
      <AllTestimonials />
      <InternalContactUs />
      <AllFaqs data={projectData.faqs} />
    </div>
  );
};

export default SolarPage;
