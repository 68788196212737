import React from "react";
import "./AllIntroduction.css";

const AllIntroduction = ({title, description, image, strongText}) => {
  return (
    <div className="all-introduction">
      <img src="/images/bgImage.png" className="all-introduction-bg" alt=""></img>
      <div className="all-introduction-content">
        <div className="all-introduction-content-left">
          <h1>{title}</h1>
          <p>{description} <strong className="bold-text">{strongText}</strong></p>
        </div>
        <div className=".all-introduction-content-right ">
          <img className="introduction-image "src={image} alt=""></img>
        </div>
      </div>
    </div>
  );
};

export default AllIntroduction;
