import React from "react";
import totalData from '../../JsonFiles/TotalData.json';
import CareersJobs from "../../components/CareersJobs/CareersJobs";
import "./ContactPage.css";

import AllIntroduction from "../../components/AllIntroduction/AllIntroduction";
import ContactMap from "../../components/ContactMap/ContactMap";
const ContactPage = () => {
  const data = totalData[9];
  return (
    <div className="contact-background">
      <AllIntroduction title={data.title} description={data.description} image={data.image}/>
      <CareersJobs />
      <ContactMap data={data.ContactMap} />
    </div>
  );
};

export default ContactPage;
