import React from "react";
import "./SolarQuestions.css";
import * as Icons from "react-icons/fa"; // Import all FontAwesome icons

function SolarQuestions() {
  const cardData = [
    {
      icon: "FaQuestionCircle",
      title: "Feeling overwhelmed about solar choices?",
      description:
        "You want solar on your roof, but don't know where to start?",
    },
    {
      icon: "FaBook",
      title:
        "Done your research and don't want to be influenced by sales people?",
      description:
        "Are you someone who does your homework? You've read blogs, watched videos and now you're ready to get a solar system?",
    },
    {
      icon: "FaBolt",
      title: "Concerned about ever increasing power costs?",
      description:
        "Every year, the energy bills keep increasing. You've had enough and want to do something about it.",
    },
  ];

  return (
    <div className="solar-feelings-container">
      <h2>
        Is this how<strong> you feel </strong>about solar?
      </h2>
      <div className="question-card-container">
        {cardData.map((card, index) => {
          const IconComponent = Icons[card.icon]; // Dynamically get the icon component
          return (
            <div className="question-card" key={index}>
              <div className="question-card-icon">
                <IconComponent className="question-icon-pri" />{" "}
                {/* Use the dynamic icon component */}
              </div>
              <div className="card-content">
                <h3>{card.title}</h3>
                <p>{card.description}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SolarQuestions;
