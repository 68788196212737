import React from "react";
import news from "../../JsonFiles/News.json";
import TotalData from "../../JsonFiles/TotalData.json";
import AllIntroduction from "../../components/AllIntroduction/AllIntroduction";
import { useParams } from "react-router-dom";
import NewsRoomContent from "../../components/NewsRoomContent/NewsRoomContent";
import InternalContactUs from "../../components/InternalContactUs/InternalContactUs";

const NewsRoomPage = () => {
  const { id } = useParams();
  const News = news.find((page) => page.id === parseInt(id));

  if (!News) {
    // Corrected from "date" to "News"
    return <div>Page not found</div>;
  }

  const HeroData = TotalData[3];

  return (
    <div>
      <AllIntroduction
        title={News.title}
        description={News.description}
        image={News.image}
      />
      <NewsRoomContent details={News.details} date={News.date} />
      <InternalContactUs/>
    </div>
  );
};

export default NewsRoomPage;
