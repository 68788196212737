import React, { Suspense, lazy } from "react";
import { Oval } from 'react-loader-spinner';

import projects from "../../JsonFiles/Projects.json";
import TotalData from "../../JsonFiles/TotalData.json";
import Services from "../../JsonFiles/Services.json";
const AllIntroduction = lazy(() =>
  import("../../components/AllIntroduction/AllIntroduction")
);
const AllServices = lazy(() =>
  import("../../components/AllServices/AllServices")
);
const AllProjects = lazy(() =>
  import("../../components/AllProjects/AllProjects")
);
const AllTeam = lazy(() =>
  import("../../components/AllTeam/AllTeam")
);
const HomePartners = lazy(() =>
  import("../../components/HomePartners/HomePartners")
);
const AllSustainability = lazy(() =>
  import("../../components/AllSustainability/AllSustainability")
);
const AllTestimonials = lazy(() =>
  import("../../components/AllTestimonials/AllTestimonials")
);
const Home = () => {
  const homedata = TotalData[3];
  return (
    <div>
      <Suspense
        fallback={
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Oval
              height={80}
              width={80}
              color="#4fa94d"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel='oval-loading'
              secondaryColor="#4fa94d"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        }
      >
        <AllIntroduction 
          title={homedata.HeadingLeft} 
          description={homedata.HeadingLeftDescription} 
          image={homedata.IntroductionImage} 
          strongText={homedata.StringHeadingLeftDescription}
        />
        <AllServices services={Services}/>
        <AllProjects cards={projects}/>
        <AllTeam teamMembers={TotalData[3].teamMembers}/>
        <AllTestimonials/>
        <AllSustainability data={TotalData[4]}/>
        <HomePartners data={TotalData[3]}/>
      </Suspense>
    </div>
  );
};

export default Home;
