import servicesData from "../../JsonFiles/Services.json"




export const links = [
  {
    name: "Solar",
    submenu: true,
    sublinks: servicesData.map((item) => ({
      title: item.title,
      id: item.id,
    })),
  },

];