import React, { useEffect } from "react";
import "./SolarHighlights.css";

function SolarComponent() {
  useEffect(() => {
    const counters = document.querySelectorAll(".counter");
    const speed = 1500; // The lower the slower

    counters.forEach((counter) => {
      const updateCount = () => {
        const target = +counter.getAttribute("data-target");
        const count = +counter.innerText;
        const increment = target / speed;

        if (count < target) {
          counter.innerText = Math.ceil(count + increment);
          setTimeout(updateCount, 1);
        } else {
          counter.innerText = target;
        }
      };

      updateCount();
    });
  }, []);

  return (
    <div className="solar-component">
      <div className="highlights-header">
        <h1>We've been installing solar for over <strong>12 years.</strong></h1>
        <p>
          We're Brisbane's Choice for Solar Energy - Join the solar revolution
          in Brisbane with Evergreen Electrical. We harness solar energy to
          power homes and businesses, contributing to a sustainable future.
        </p>
        <p>
          We are proud of what we have achieved so far, but we can't do it
          alone. 2 million Australians have already made the jump to solar. You
          can too.
        </p>
      </div>

      <div className="body">
        <div className="statistics">
          <div className="highlights-statistic">
            <img src="/images/icons/solar_solar_panel.png" alt="Solar Panel" />
            <div>
              <h3>Panels Installed</h3>
              <h2 className="counter" data-target="134400">
                0
              </h2>
            </div>
          </div>
          <div className="highlights-statistic">
            <img src="/images/icons/solar_tree.png" alt="Tree" />
            <div>
              <h3>Equivalent Trees Planted</h3>
              <h2 className="counter" data-target="163000">
                0
              </h2>
            </div>
          </div>
          <div className="highlights-statistic">
            <img src="/images/icons/solar_vehicle.png" alt="Vehicle" />
            <div>
              <h3>Equivalent Passenger Cars Carbon Saved</h3>
              <h2 className="counter" data-target="1460">
                0
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SolarComponent;
