import React from "react";
import TotalData from "../../JsonFiles/TotalData.json";
import projects from "../../JsonFiles/Projects.json";
import AllIntroduction from "../../components/AllIntroduction/AllIntroduction";
import AllAboutSection from "../../components/AllAboutSection/AllAboutSection";
import AllSustainability from "../../components/AllSustainability/AllSustainability";
import AllTestimonials from "../../components/AllTestimonials/AllTestimonials";
import AllProjects from "../../components/AllProjects/AllProjects";
import SolarHighlights from "../../components/SolarHighlights/SolarHighlights";
import CompanyPdfViewer from "../../components/CompanyPdfViewer/CompanyPdfViewer";

const AboutPage = () => {
  const aboutData = TotalData[4];
  return (
    <div>
      <AllIntroduction
        title={aboutData.title}
        description={aboutData.description}
        image={aboutData.AboutUsImage}
      />
      <AllAboutSection data={TotalData[4]} />
      <CompanyPdfViewer data={aboutData.PdfCompany} />
      <SolarHighlights />
      <AllSustainability data={TotalData[4]} />
      <AllTestimonials testimonials={TotalData[3].CustomersTestimonials} />
      <AllProjects cards={projects} />
    </div>
  );
};

export default AboutPage;
