import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import NavBar from "./components/NavBar/NavBar";
import Footer from "./pages/Footer/Footer";
import AboutPage from "./pages/AboutPage/AboutPage";
import SolarPage from "./pages/SolarPage/SolarPage";
import NewsRoom from "./pages/NewsRoom/NewsRoom";
import NewsRoomPage from "./pages/NewsRoomPage/NewsRoomPage";
import Projects from "./pages/Projects/Projects";
import ProjectPage from "./pages/ProjectPage/ProjectPage";
import ContactPage from "./pages/ContactPage/ContactPage";
import SolarCalculatorPage from "./pages/SolarCalculatorPage/SolarCalculatorPage";
import PopupForm from "./components/PopupForm/PopupForm";

const AppRoutes = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Router>
      <div>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/news" element={<NewsRoom />} />
          <Route path="/news/:id" element={<NewsRoomPage />} />
          <Route path="/project" element={<Projects />} />
          <Route path="/projects/:id" element={<ProjectPage />} />
          <Route path="/solar/:id" element={<SolarPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="/solarCalculator" element={<SolarCalculatorPage />} />
        </Routes>
        <Footer />
        <button className="fixed-button" onClick={handleOpen}>Text Us</button>
        <PopupForm open={open} handleClose={handleClose} />
      </div>
    </Router>
  );
};

export default AppRoutes;
