import React, { useState } from "react";
import "./PopupForm.css";

const PopupForm = ({ open, handleClose }) => {
  const [formData, setFormData] = useState({
    field1: "",
    field2: "",
    field3: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    handleClose();
  };

  if (!open) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="form-close" onClick={handleClose}>
          &times;
        </span>
        <h2>Solar Service</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-field">
            <label>Name</label>
            <input
              type="text"
              name="field1"
              value={formData.field1}
              onChange={handleChange}
            />
          </div>
          <div className="form-field">
            <label>Phone No.</label>
            <input
              type="text"
              name="field2"
              value={formData.field2}
              onChange={handleChange}
            />
          </div>
          <div className="form-field">
            <label>Message</label>
            <input
              type="text"
              name="field3"
              value={formData.field3}
              onChange={handleChange}
            />
          </div>
          <p>
            By submitting, you authorize us to text and call
            the number you provided with offers & other information, possibly
            using automated means. Message/data rates apply. Consent is not a
            condition of purchase
          </p>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default PopupForm;
