import React from 'react';
import Button from '../Button/Button';
import "./NewsRoomList.css";
import { Link as RouterLink } from 'react-router-dom';

const Link = ({ to, children, ...rest }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <RouterLink to={to} onClick={handleClick} {...rest}>
      {children}
    </RouterLink>
  );
};

const NewsRoomList = ({ data }) => {
  return (
    <div className='NewsRoomList-Container'>
      <div className='NewsRoomList-AllCards'>
        {data.map((newsItem) => (
          <Link key={newsItem.id} to={`/news/${newsItem.id}`} className='NewsRoomList-CardLink'>
            <div className='NewsRoomList-Card'>
              <div className='NewsRoomList-CardLeft'>
                <img src={newsItem.image} alt={newsItem.title} className='NewsRoomList-image' />
              </div>
              <div className='NewsRoomList-CardRight'>
                <p className='NewsRoomList-Date'>{newsItem.date}</p>
                <h1 className='NewsRoomList-Title'>{newsItem.title}</h1>
                <p className='NewsRoomList-Description'>{newsItem.description}</p>
                <div className='NewsRoomList-ButtonContainer'>
                  <Button to={`/news/${newsItem.id}`}>CONTINUE READING</Button>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default NewsRoomList;
