import React from "react";
import Button from '../Button/Button';
import "./InternalContactUs.css"; // Import the CSS file for styling

const ContactUs = () => {
  return (
    <div className="contact-us-container">
    <img src="/images/icons/board_pin.png" className="board-pin"/>
      <p>
        If you have any questions or need further information, feel free to
        reach out to us.
      </p>
      <Button to={"/contact"} >Click Here</Button>
    </div>
  );
};

export default ContactUs;
