import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import "./Footer.css";
import servicesData from "../../JsonFiles/Services.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {

  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import TotalData from "../../JsonFiles/TotalData.json";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

// Custom Link component to handle scroll behavior
const Link = ({ to, children, ...rest }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <RouterLink to={to} onClick={handleClick} {...rest}>
      {children}
    </RouterLink>
  );
};

const Footer = () => {
  // Define animations
  const animationControls = useAnimation();
  const [ref, inView] = useInView();

  // Animate when the element comes into view
  useEffect(() => {
    if (inView) {
      animationControls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 1 },
      });
    }
  }, [animationControls, inView]);

  const totalData = TotalData[2]; // Accessing TotalData[2] directly

  return (
    <motion.div
      className="footer"
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={animationControls}
    >
      {inView && (
        <button
          className="scrollToTopButton"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          <FontAwesomeIcon icon={faArrowUp} />
        </button>
      )}

      <div className="footer-container">
        <img className="footer-column" src="/images/rohit_solar2.png" alt=""></img>
        <div className="footer-column">
     
          <h2>
            <Link to="/services">Solar</Link>
          </h2>
          <ul>
            {servicesData.slice(0, 10).map((service) => (
              <li key={service.id}>
                <Link to={`/solar/1`}>{service.title}</Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="footer-column">
         
          <h2>
            <Link to="/company">About Us</Link>
          </h2>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/why-priya">Why Us?</Link>
            </li>
            <li>
              <Link to="/company">News Room</Link>
            </li>
            <li>
              <Link to="/contact">Project</Link>
            </li>
            <li>
              <Link to="/career">Contact</Link>
            </li>
          </ul>
        </div>

        <div className="location-cards-container">
          <div className="location-card">
            <img
              src={totalData.ImagesLogo}
              alt="Logo"
              className="footer-logo"
            />
            <h2>
              <a
                href={totalData.CompanyLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {totalData.CompanyName}
              </a>
            </h2>
            <a href={`mailto:${totalData.Email}`}>Email: {totalData.Email}</a>
            <div>
              <p>
                Head Office:{" "}
                <a
                  href={totalData.Location1}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {totalData.Address1}
                </a>
              </p>
              <p>
                <a
                  href={totalData.Location2}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {totalData.Address2}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <p className="copyRight">
          &copy; {new Date().getFullYear()} All rights reserved by{" "}
          {totalData.CompanyName}. Website Designed & Maintained by{" "}
          <a href="https://innotechconsultant.co.in/" className="link-innotech">
            Innotech Consultant
          </a>
          .
        </p>
      </div>
    </motion.div>
  );
};

export default Footer;
