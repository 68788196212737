import React from "react";
import "./AllAboutSection.css";
import TotalData from "../../JsonFiles/TotalData.json"

const AllAboutSection = () => {
  const data = TotalData[10];
  return (
    <div className="AboutUs-container">
      <div className="AboutUs-left">
        <h1 className="AboutUS-text">{data.AboutUsHeading}</h1>
        <h1 className="AboutUs-heading">{data.AboutUsHeading}</h1>
        <p className="Paragraph-style">
          {data.AboutUsDescription}
        </p>
      </div>
      <div className="about-shape">
        <img  className="office-image" src={data.AboutUsImage} alt="thisimage"></img>
      </div>
    </div>
  );
};

export default AllAboutSection;
