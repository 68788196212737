import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./AllTestimonials.css";
import homeData from "../../JsonFiles/TotalData.json";


const TestimonialSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <button className="slick-prev" type="button"></button>,
    nextArrow: <button className="slick-next" type="button"></button>,
    responsive: [
      {
        breakpoint: 768, // screen width at which to apply the new settings
        settings: {
          slidesToShow: 1, // number of slides to show on mobile
          prevArrow: <button className="slick-prev" type="button"></button>,
          nextArrow: <button className="slick-next" type="button"></button>,
        },
      },
    ],
  };

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(
        <span key={i} className={`star ${i < rating ? "filled" : ""}`}>
          ★
        </span>
      );
    }
    return stars;
  };

  const data = homeData[3];

  return (
    <div className="testimonial-slider">
      <img src="/images/my_quote.png" className="quote-symbol" alt=""></img>
      <h2>Customer Testimonials</h2>
      <Slider {...settings}>
        {data.CustomersTestimonials.map((testimonial, index) => (
          <div key={index} className="testimonial">
            <div className="rating">{renderStars(testimonial.rating)}</div>
            <blockquote className="quote">{testimonial.quote}</blockquote>
            <p className="author">- {testimonial.author}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TestimonialSlider;
