import React, { useState, useEffect } from "react";
import { Link as RouterLink, useLocation } from 'react-router-dom';
import NavLinks from "./NavLinks";
import LazyLoad from 'react-lazyload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import Button from '../Button/Button';
import "./NavBar.css";

const Link = ({ to, children, ...rest }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <RouterLink to={to} onClick={handleClick} {...rest}>
      {children}
    </RouterLink>
  );
};

const NavBar = () => {
  const [open, setOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  const handleLinkClick = () => {
    setOpen(false);
  };

  useEffect(() => {
    const checkScroll = () => {
      if (location.pathname === "/") {
        setIsScrolled(window.scrollY > 100);
      } else {
        setIsScrolled(window.scrollY > 0);  // To detect scrolling on non-home pages as well
      }
    };

    checkScroll();  // Initialize state on mount or location change

    window.addEventListener('scroll', checkScroll);

    return () => {
      window.removeEventListener('scroll', checkScroll);
    };
  }, [location]);

  return (
    <nav id="animated-navbar" className={`sticky top-0 z-50 transition-colors duration-500 ${isScrolled ? 'shadow-lg bg-white' : 'bg-[#E6FFE4]'}`}>
      <div className="container mx-auto px-4">
        <div className="flex items-center font-medium justify-between w-4/5 mx-auto">
          <div className="z-50 p-2 flex items-center">
            <Link to="/">
              <LazyLoad height={200} offset={100} once>
                <img
                  src="/images/rohit_solar2.png"
                  alt="logo"
                  className="w-40"
                />
              </LazyLoad>
            </Link>
          </div>
          <div className="flex items-center">
            <ul className={`2xl:flex hidden uppercase items-center gap-12 font-[Poppins] text-black`}>
              <li>
                <Link to="/about" className="py-7 px-2 inline-block hover:text-[#922622] transform transition-transform duration-300">
                  Why US?
                </Link>
              </li>
              <NavLinks isScrolled={isScrolled} />
              <li>
                <Link to="/news" className="py-7 px-2 inline-block hover:text-[#922622] transform transition-transform duration-300">
                  News Room
                </Link>
              </li>
              <li>
                <Link to="/project" className="py-7 px-2 inline-block hover:text-[#922622] transform transition-transform duration-300">
                  Projects
                </Link>
              </li>
              <li className="nav-contact-button">
                <Button to={"contact"}>Contact</Button>
              </li>
            </ul>
            <div className={`text-5xl 2xl:hidden z-50`} onClick={() => setOpen(!open)}>
              <FontAwesomeIcon icon={open ? faTimes : faBars} color={open ? 'black' : (isScrolled ? 'black' : 'black')} />
            </div>
          </div>
          {/* mobile navbar */}
          <ul
            className={`
              xl:hidden bg-white fixed w-full top-0 overflow-y-auto bottom-0 py-24 pl-4
              duration-500 ${open ? "left-0" : "left-[-100%]"}
            `}
          >
            <li>
              <Link to="/about" className="py-7 px-2 inline-block hover:text-[#922622]" onClick={handleLinkClick}>
                Why US?
              </Link>
            </li>
            <NavLinks handleLinkClick={handleLinkClick} />
            <li>
              <Link to="/news" className="py-7 px-2 inline-block hover:text-[#922622]" onClick={handleLinkClick}>
                News Room
              </Link>
            </li>
            <li>
              <Link to="/project" className="py-7 px-2 inline-block hover:text-[#922622]" onClick={handleLinkClick}>
                Projects
              </Link>
            </li>
            <li>
              <Link to="/contact" className="py-7 px-2 inline-block hover:text-[#922622]" onClick={handleLinkClick}>
                Contact
              </Link>
            </li>
            <li>
              <div className="py-5"></div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
