import React from "react";
import totalData from "../../JsonFiles/TotalData.json";
import projects from "../../JsonFiles/Projects.json";
import AllIntroduction from "../../components/AllIntroduction/AllIntroduction";
import ProjectCardRow from "../../components/ProjectCardRow/ProjectCardRow";

const Projects = () => {
  const data = totalData[7];

  return (
    <div>
      <AllIntroduction
        title={data.title}
        description={data.description}
        image={data.image}
      />

      <ProjectCardRow data={projects} />
    </div>
  );
};

export default Projects;
