import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import Button from "../Button/Button";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./SolarCalculator.css";

// Register the required components from ChartJS
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function SolarCalculator() {
  const [energyUsage, setEnergyUsage] = useState("Low");
  const [systemSize, setSystemSize] = useState("6.6kW");
  const [savings, setSavings] = useState({ year1: 0, year10: 0, year20: 0 });
  const [currentRate, setCurrentRate] = useState(0);
  const [billWithoutSolar, setBillWithoutSolar] = useState(0);
  const [billWithSolar, setBillWithSolar] = useState(0);
  const [totalSavings, setTotalSavings] = useState(0);

  const calculateSavings = () => {
    let baseRate = 0;

    switch (energyUsage) {
      case "Low":
        baseRate = 100;
        break;
      case "Medium":
        baseRate = 200;
        break;
      case "High":
        baseRate = 300;
        break;
      default:
        baseRate = 0;
    }

    let sizeMultiplier = 1;
    switch (systemSize) {
      case "6.6kW":
        sizeMultiplier = 1;
        break;
      case "10.6kW":
        sizeMultiplier = 1.5;
        break;
      case "13.2kW":
        sizeMultiplier = 2;
        break;
      default:
        sizeMultiplier = 1;
    }

    const yearlySavings = baseRate * sizeMultiplier;
    setSavings({
      year1: yearlySavings,
      year10: yearlySavings * 10,
      year20: yearlySavings * 20,
    });

    setCurrentRate(baseRate * sizeMultiplier);

    const yearlyBillWithoutSolar = baseRate * 365;
    const yearlyBillWithSolar = yearlyBillWithoutSolar - yearlySavings;
    setBillWithoutSolar(yearlyBillWithoutSolar);
    setBillWithSolar(yearlyBillWithSolar);
    setTotalSavings(yearlyBillWithoutSolar - yearlyBillWithSolar);
  };

  useEffect(() => {
    calculateSavings();
  }, [energyUsage, systemSize]);

  const Card = ({ label, lableValue, value, selectedValue, onClick }) => (
    <div
      className={`selectable-card ${value === selectedValue ? "selected" : ""}`}
      onClick={() => onClick(value)}
    >
      <div>{label}</div>
      <div>{lableValue}</div>
    </div>
  );

  const data = {
    labels: ["1 Year", "10 Years", "20 Years"],
    datasets: [
      {
        label: "Savings ($)",
        data: [savings.year1, savings.year10, savings.year20],
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        type: "linear",
        beginAtZero: true,
        max: 14000,
      },
    },
    animation: {
      duration: 2000, // Animation duration in milliseconds
      easing: "easeInOutQuad", // Animation easing function
    },
  };

  return (
    <div className="Solar-calculator-container">
      <div className="calculator-left-section">
        <div className="calculator-container">
          <h2>Calculator</h2>
          <div className="row">
            <div className="container">
              {/* Step 1: Energy Usage */}
              <div className="energy-usage">
                <h3>Step 1: Energy Usage</h3>
                <div className="selectable-card-container">
                  <Card
                    label="Low"
                    lableValue="<15kWh/day"
                    value="Low"
                    selectedValue={energyUsage}
                    onClick={setEnergyUsage}
                  />
                  <Card
                    label="Medium"
                    lableValue="15-25kWh/day"
                    value="Medium"
                    selectedValue={energyUsage}
                    onClick={setEnergyUsage}
                  />
                  <Card
                    label="High"
                    lableValue=">25kWh/day"
                    value="High"
                    selectedValue={energyUsage}
                    onClick={setEnergyUsage}
                  />
                </div>
              </div>
              <p>
                Typically a family of 3-4 occupants who use a moderate amount of
                electrical appliances at home such as a home office, oven or a
                clothes dryer. May use air condition or electrical heaters
                occassionally
              </p>
              {/* Step 2: Solar System Size */}
              <div className="system-size">
                <h3>Step 2: Solar System Size</h3>
                <div className="selectable-card-container">
                  <Card
                    label="6.6kW"
                    value="6.6kW"
                    selectedValue={systemSize}
                    onClick={setSystemSize}
                  />
                  <Card
                    label="10.6kW"
                    value="10.6kW"
                    selectedValue={systemSize}
                    onClick={setSystemSize}
                  />
                  <Card
                    label="13.2kW"
                    value="13.2kW"
                    selectedValue={systemSize}
                    onClick={setSystemSize}
                  />
                </div>
              </div>

              {/* Step 3: Current Rate */}
              <div className="current-rate">
                <h3>Current Rate</h3>
                <h3>${currentRate} per year</h3>
              </div>
            </div>
          </div>
        </div>
        <Button to={"/contact"}>Get a Quote</Button>
      </div>
      <div className="calculator-right-section">
        <div className="calculator-bill-comparison">
          <h2>Bill Comparison</h2>
          <div className="bill-details">
            <p>
              Bill without Solar: <span>${billWithoutSolar} per year</span>
            </p>
            <p>
              Bill with Solar: <span>${billWithSolar} per year</span>
            </p>
            <p>
              Total Savings: <span>${totalSavings} per year</span>
            </p>
          </div>
        </div>

        <div className="graph-container">
          <h2>Savings Over Time</h2>
          <div className="chart-container">
            <Bar data={data} options={options} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SolarCalculator;
